import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import Button from './ui/Button';
import { withCookies } from 'react-cookie';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { theme } from './Theme';
import { CTA } from 'r3-lib/dist/r3lib';

const animationLength = 800;

const Wrapper = styled('div')`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  margin-left: -12rem;
  padding: 2rem;
  text-align: center;
  background-color: ${theme.colors.lightgrey};
  width: 26rem;
  max-width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation-name: enter;
  animation-duration: ${animationLength}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 50;

  &.unmounting {
    animation-name: exit;
  }
  ${theme.below.lg}{
    width: 20rem;
    left: 50%;
    margin-left: -10rem;
  }
  @keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes exit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  a {
    display: block;
    margin-bottom: 1.5rem;
    color: ${theme.colors.variant.black};
    font-size: ${theme.fontSizes.mobile.p1};
    font-weight: ${theme.fontWeights.bold};
    text-transform: uppercase;
    text-decoration: none;
  
    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 1rem;
    font-size: 20px;
  }
  .cta {
    text-transform: uppercase;
  }
  .link-evaluator {
    ${theme.fonts.mobile.h4}
    letter-spacing: 0.1em;
  }
  button {
    width: 100%;
    height: 46px;
    border-radius: 999px;
    ${theme.fonts.desktop.h4}
    color: ${theme.colors.lightgrey};
    background-color: ${theme.colors.variant.black};
  
    :hover {
      color: ${theme.colors.lightgrey};
      background-color: ${theme.colors.darkgrey};
    }
  }
  `;

const CookieConsent = ({ cookies }) => {
  const { cookieConsent } = cookies.cookies;

  const [visible, setVisible] = useState(cookieConsent !== 'agreed');
  const [unmounting, setUnmounting] = useState(false);

  function agree() {
    cookies.set('cookieConsent', `agreed`);
    setUnmounting(true);
    setTimeout(() => {
      setVisible(false);
    }, animationLength);
  }

  useEffect(() => {
    window.consent = agree;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return visible ? (
    <Wrapper className={unmounting ? 'unmounting' : ''}>
      <p>
        {t('This website uses cookies to ensure you get the best experience.')}
      </p>
      <Link to="/terms-and-conditions">{t('Learn more')}</Link>
      {/* <Button onClick={agree}>{t('Got it!')}</Button> */}
      <CTA className={'dark'} clickCallback={agree}>
        {t('Got it!')}
      </CTA>
    </Wrapper>
  ) : null;
};

export default withCookies(CookieConsent);
