import { css } from 'linaria';
import { theme } from '../../../Theme';

export const cartProductCardStyle = css`
  padding: 20px 0;
  border-top: 1px solid ${theme.colors.grey};
  display: flex;
  gap: 20px;
  text-decoration: none;

  [data-flight-image-container] {
    width: 90px !important;
    height: 90px !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-information {
    justify-content: space-between;
    flex-grow: 1;
    .link-evaluator {
      color: ${theme.colors.darkgrey};
      text-decoration: none;
    }

    h4 {
      font-size: 11px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.1px;
      text-transform: uppercase;
    }

    p {
      font-size: 13px;
      font-weight: 300;
      line-height: 150%; /* 19.5px */
    }
  }

  .cart-product-price {
    white-space: nowrap;
    font-weight: ${theme.fontWeights.medium} !important;
  }

  .remove {
    button {
      background: transparent;
      .svg-wrapper {
        margin: 4px;
        background: ${theme.colors.darkgrey};
        height: 16px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: ${theme.colors.white};
          width: 6px;
        }
      }
    }
  }
`;
