import { css } from 'linaria';
import { theme } from '../../Theme';

// Use linaria css to style the component.
// All styling should be placed in :global().
// Begin all styling with root class name of component.

css`
  :global() {
    .hero {
      position: relative;
      max-width: ${theme.layout.maxWidth};

      .content-positioner {
        padding: 70px;
      }

      &.fullWidth {
        max-width: 100%;
      }
    }
  }
`;
