import React from 'react';
import { ProductRow } from '../ProductRow/ProductRow';
import { productRowStyle } from '../ProductRow/ProductRow.style';
import { ProductRowItem } from '../ProductRowItem/ProductRowItem';
import { theme } from '../../Theme';
import { styled } from 'linaria/react';
// import { RowHeader } from '../ProductRow/ProductRow.jsx';

const CategoryRowOuterWrapper = styled('div')`
  width: 100%;
  margin: auto;
  display: flex;

  .product-row-wrapper {
    margin: 44px 0 30px 0;
  }

  .swiper-slide-active {
    margin-left: -10px;
  }

  .swiper-slide-prev {
    margin-left: -10px;
    margin-right: 10px;
  }

  .wrapper {
    padding-left: 30px;
  }

  ${theme.below.lg} {
    flex-direction: column;
    .product-row-wrapper {
      margin: 0;
    }
    .wrapper {
      padding-left: 20px;
    }
    .swiper-button-prev {
      display: none;
    }
  }
`;

export const CategoryRow = ({ fullWidth, padding, gap, category, style }) => {
  //TIP: make sure you are getting products from category (check gql)
  const products = category?.value?.products?.result;

  if (!products) {
    console.warn(
      `Products missing for ${category?.name}(ID ${category.id}) *** Check gql ***`
    );
  }

  return (
    <>
      {products?.length > 0 && (
        <ProductRow
          fullWidth={fullWidth}
          padding={padding}
          gap={gap}
          style={style}
        >
          {products?.map((product, index) => {
            return (
              <ProductRowItem
                key={`prod-${index}-${product.articleNumber}`}
                product={product}
              />
            );
          })}
        </ProductRow>
      )}
    </>
  );
};

export const StyledCategoryRow = ({
  fullWidth,
  padding,
  gap,
  category,
  Children,
  title,
  link,
  buttonText,
  backgroundColor,
  textColor
}) => {
  return (
    <CategoryRowOuterWrapper
      style={{
        maxWidth: `${fullWidth?.value ? '100%' : theme.layout.maxWidth}`,
        padding: `0 ${padding?.value ?? 0}px`
      }}
    >
      {/* <RowHeader
        fewCards={category?.value?.products?.result?.length < 3}
        title={title?.value}
        buttonText={buttonText?.value}
        backgroundColor={backgroundColor?.value}
        textColor={textColor?.value}
        link={link?.value}
      /> */}
      <CategoryRow
        fullWidth={fullWidth}
        padding={padding}
        gap={gap}
        category={category}
        style={productRowStyle}
      >
        {Children}
      </CategoryRow>
    </CategoryRowOuterWrapper>
  );
};
