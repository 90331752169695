import { css } from 'linaria';
import { theme } from '../../../Theme';

export const paymentMethodsRowStyle = css`
  width: 100%;
  padding: 1rem 0;

  .max-width {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;

    p {
      font-size: 14px;
      font-weight: 300;
    }

    .icon {
      height: 100%;
      max-height: 40px
      width: auto;
      max-width: 100px;

      &.Klarna {
        height: 15px;
        order: 4;
      }
      &.Visa {
        height: 14px;
        order: 2;
      }
      &.Mastercard {
        height: 35px;
        order: 1;
      }
      &.Swish {
        height: 30px;
        order: 3;
      }
    }

    .footer-payment-methods{
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 30px;
      align-items: center;
      justify-content: center;

      ${theme.below.lg} {
        gap: 25px;
    }
  }
`;
