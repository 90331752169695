import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React from 'react';
import { theme } from '../Theme';
import { Favourite } from '../ProductList/Favourite';
import { ReactComponent as FavouritesCart } from '../../svg/FavouritesCart.svg';
import { aspectRatioAdjustment } from 'r3-lib/dist/r3lib';
import { QuickBuyButton } from '../Layout/QuickBuy/QuickBuyButton';
import { Link } from 'react-router-dom';
import { usePriceDisplay } from '../../hooks/usePriceDisplay';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  [data-flight-image-container] {
    background: ${theme.colors.lightgrey};
  }
  [data-flight-image] {
    img {
      mix-blend-mode: multiply;
      transform: translate3d(0, 0, 0);
    }
  }

  .product-card-detail {
    font-family: ${theme.fonts.primaryFontFamily};
    background: white;
    padding: 10px 0;

    header {
      margin-bottom: 10px;

      ${theme.below.lg} {
        margin-bottom: 6px;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      color: #000;
    }

    h4 {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      font-family: 'Outfit';
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-and-quickbuy-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  .price-package {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 0px;

    ${theme.below.lg} {
      padding-right: 10px;
      width: 90%;
    }
    ${theme.below.sm} {
      font-size: 14px;
    }

    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
    p {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 16px;
      color: #000;

      @media screen and (max-width: 420px) {
        font-size: 16px;
      }
    }
    span {
      font-family: 'Outfit';
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000;
    }
  }

  .badge-top-right {
    top: 3em;
  }
  .quickbuy {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      z-index: 2;
      padding: 10px;
      border-radius: 50%;
      margin-top: 10px;
      svg {
        width: 19px;
        height: 16px;
      }
    }
  }

  .no-image-wrapper {
    position: relative;
    .no-image {
      background: #dbdbdb;
    }
    .no-image-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const FavouriteWrapper = styled('div')`
  position: absolute;
  top: 5px;
  right: 18px;
  z-index: 1;

  button {
    border: none;
  }
`;

export const StyledPrice = styled(Price)`
  display: flex;
  align-items: flex-end;

  .price {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #000;

    @media screen and (max-width: 420px) {
      font-size: 15px;
    }
  }
  .new-price {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #c94b4b;
  }
  .old-price {
    font-size: 15px;
    color: #797979;
    text-decoration: line-through;
    margin-left: 10px;
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  loadImageEagerly = false,
  favourites,
  ...linkProps
}) {
  const { showPrice } = usePriceDisplay();
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...(product.badges || [])];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const brand = product?.customFields?.filter(
    customField => customField.key === 'Varumarke'
  );

  //NOTE: This is a temporary fix for the brand name made for release 1.0.0 - Needs update!
  const model =
    product?.name?.split(' ')[0] + ' ' + product?.name?.split(' ')[1];
  const specifikation = product?.name
    ?.split(' ')
    .slice(2)
    .join(' ');

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <Link
        to={product.primaryRoute.path}
        className="product-link"
        product={product}
        {...linkProps}
        style={{
          margin: '0 10px 10px'
        }}
      >
        {hasImages ? (
          <>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
              modifiedDate={product.images[0].modifiedDate}
              badges={<Badges badges={badges} />}
              critical={loadImageEagerly}
              cover={true}
            />
            <FavouriteWrapper className="favourite-wrapper">
              <Favourite product={product} />
            </FavouriteWrapper>
          </>
        ) : (
          <>
            {transparentDataImg && (
              <div className="no-image-wrapper">
                <img
                  className="no-image"
                  style={{
                    width: '100%',
                    aspectRatio: aspectRatioAdjustment(imageAspect)
                  }}
                  src={transparentDataImg}
                  alt="image is missing"
                />
                <p className="no-image-text">Bild saknas</p>
                <FavouriteWrapper className="favourite-wrapper">
                  <Favourite product={product} />
                </FavouriteWrapper>
              </div>
            )}
          </>
        )}
        <section className="product-card-detail">
          <header>
            <h3 className="name">{model}</h3>
            <h3 className="sub-name">{specifikation}</h3>
          </header>
          <div className="price-and-quickbuy-wrapper">
            {showPrice ? (
              <>
                <div className="price-package">
                  <p>
                    {product?.price?.exVat} kr
                    <br />
                    <span> ex. moms</span>
                  </p>
                </div>
                <div className="quickbuy">
                  <QuickBuyButton icon={true} product={product} />
                </div>
              </>
            ) : (
              <>
                <div className="price-package">
                  <p className="price">Rek. {product?.price?.incVat} Kr</p>
                </div>
              </>
            )}
          </div>
        </section>
      </Link>
      {children}
    </Tag>
  );
}
