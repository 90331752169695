import React, { useState } from 'react';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { cx } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { StyledPrice } from '../../../CategoryPage/ProductCard';
import { cartProductCardStyle } from './CartProductCard.style';
import { theme } from '../../../Theme';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import { QtyAdjuster } from '../QtyAdjuster';
import removeFromCartMutation from '../../../Cart/queries/removeFromCart.gql';
import incrementQuantityMutation from '../../../Cart/queries/incrementQuantity.gql';
import decrementQuantityMutation from '../../../Cart/queries/decrementQuantity.gql';
import setQuantityMutation from '../../../Cart/queries/setItemQuantity.gql';
import cartQuery from '../../../Cart/queries/cartQuery.gql';
import { SVG } from '../../../../assets/SVG';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';

export const CartProductCard = ({ item, linkClickCallback }) => {
  const {
    // isVariant,
    variantImage,
    // variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const { setQuantity } = useSetQuantity({
    setQuantityMutation,
    cartQuery
  });

  const handleInCrementQuantity = () => {
    incrementQuantity({ itemId: item.id });
  };
  const handleDeCrementQuantity = () => {
    decrementQuantity({ itemId: item.id });
  };

  const handleSetQantity = newQty => {
    setQuantity({ itemId: item.id, quantity: newQty });
  };

  const StyledPriceCart = styled(Price)`
    display: flex;
    align-items: flex-end;

    .price {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #000;
    }
    .new-price {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #c94b4b;
    }
    .old-price {
      font-size: 15px;
      color: #797979;
      text-decoration: line-through;
      margin-left: 10px;
    }
  `;

  // const variantNamesValues = variantValues?.map(
  //   (value, index) => `${item?.variantOptionNames?.[index]}: ${value}`
  // );

  // const isFreeItem =
  //   item.discounts?.filter(d => {
  //     return d.type === 'FREE_PRODUCT';
  //   }).length > 0;

  const product = item?.product;
  const productImage = hasVariantImage
    ? variantImage
    : product?.images?.[0]?.url ?? transparentDataImg;

  return (
    <div className={cx('cart-product-card', cartProductCardStyle)}>
      <Image
        sizes={theme.layout.imageSizes.grid}
        aspect={theme.layout.productImageAspectRatio}
        alt={product?.images?.[0]?.alt}
        src={productImage}
        fillAvailableSpace={true}
        modifiedDate={product?.images?.[0]?.modifiedDate}
        critical={true}
      />

      <div className="product-information">
        <LinkEvaluator
          linkClickCallback={linkClickCallback}
          link={product?.primaryRoute?.path}
        >
          {/* <h4>{product?.subName?.length > 0 ? product?.subName : 'Brand'}</h4> */}
          <h4>{product?.name}</h4>
        </LinkEvaluator>
        <QtyAdjuster
          qty={item?.quantity}
          incrementCallback={handleInCrementQuantity}
          decrementCallback={handleDeCrementQuantity}
          setQuantityCallback={handleSetQantity}
        />
      </div>

      <div className="cart-product-price">
        {/* <StyledPriceCart
          hidePrice={product?.hidePrice}
          price={product?.price}
          previousPrice={product?.previousPrice}
        /> */}
        <p className="price">{product?.price?.exVat}kr</p>
      </div>
      <div className="remove">
        <button onClick={() => removeFromCart({ itemId: item.id, product })}>
          <div className="svg-wrapper">
            <SVG name={'X'} />
          </div>
        </button>
      </div>
    </div>
  );
};
