import { css } from 'linaria';

export const productRowStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .wrapper {
    width: 100%;
    margin: auto;
  }

  &.fullWidth {
    .wrapper {
      max-width: 100%;
    }
  }
`;
