import React from 'react';
import { SVG } from '../../../../assets/SVG';
import { cx } from 'linaria';
import MaxWidth from '../../MaxWidth';
import { footerContentStyle } from './FooterContent.style';
import { Above } from '@jetshop/ui/Breakpoints';
import { footerLinksColumnStyle } from '../FooterLinksColumn/FooterLinksColumn.style';
import img from '../../../../assets/we_optics_logo_footer.png';
import t from '@jetshop/intl';

export const FooterContent = ({
  backgroundColor,
  foregroundColor,
  showLogo,
  title,
  text,
  children
}) => {
  const filterCEItemsWithKey = (children, key) => {
    const filtered = [];
    const rest = [];

    children?.forEach(child => {
      if (child?.key.startsWith(key)) {
        filtered.push(child);
      } else {
        rest.push(child);
      }
    });

    return { filtered, rest };
  };

  const { filtered: socialMediaLinks, rest } = filterCEItemsWithKey(
    children,
    'socialMediaLinks'
  );

  const FooterLogo = () => {
    const imgPath = '../../../../assets/we_optics_logo_footer.png';
    return (
      <div className="footer-logo">
        {showLogo?.value && <img src={img} alt="We optics logo" />}
      </div>
    );
  };

  return (
    <Above breakpoint="lg">
      {matches => (
        <div
          className={cx('footer-content', footerContentStyle)}
          style={{
            background: backgroundColor?.value,
            color: foregroundColor?.value
          }}
        >
          <MaxWidth className="max-width">
            <div className="full-grid">
              {!matches ? null : <FooterLogo />}
              <div className="text-info">
                <div>
                  {title?.value?.length > 0 && <h1>{title?.value}</h1>}
                  {text?.value?.length > 0 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: text?.value
                      }}
                    />
                  )}
                </div>
              </div>

              {matches ? null : <FooterLogo />}
              {!matches && socialMediaLinks.length < 1 && (
                <div>
                  <p className="follow-us">{t('Follow us')}</p>
                  {socialMediaLinks}
                </div>
              )}

              <div className={cx('column-grid', footerLinksColumnStyle)}>
                {rest}
              </div>
            </div>
          </MaxWidth>
        </div>
      )}
    </Above>
  );
};
