import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';
import { SVG } from '../../../assets/SVG';

export const FlyoutContentWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  background: ${theme.colors.white};

  ${theme.below.lg} {
    padding-bottom: 3rem;
  }

  svg {
    height: 15px;
  }

  .categories,
  .sub-menu {
    display: flex;
    flex-direction: column;

    > button,
    > a {
      padding: 0 14px;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.fonts.mobile.h3}
      font-weight: ${theme.fontWeights.medium};
      text-decoration: none;
      background: ${theme.colors.white};
      color: ${theme.colors.black};

      &.backbutton {
        flex-direction: row-reverse;

        svg {
          transform: rotate(-180deg);
        }
      }

      &.show-all {
        background: ${theme.colors.white};
        font-weight: ${theme.fontWeights.medium};
      }

      svg {
        margin-bottom: 3px;
      }
    }

    button {
      font-weight: ${theme.fontWeights.medium};
    }
  }
  .sub-menu {
    background: transparent;
    > button,
    > a {
      background: transparent;

      svg {
        height: 18px;
        width: auto;
        margin-right: 12px;
        &.bigger {
          height: 22px;
        }
      }
    }
  }
`;

export const FlyoutMenuLayout = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  routes,
  close,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const backHandler = () => {
    setActiveId(null);
  };

  return (
    <>
      {active && (
        <>
          <FlyoutContentWrapper active={active}>
            <div className="categories">
              {parent && (
                <>
                  <button onClick={goBack} className="backbutton">
                    <span>{parent?.name}</span>

                    <div>
                      <SVG name={'Chevron_right'} />
                      <span style={{ marginLeft: '14px' }}>{t('Back')}</span>
                    </div>
                  </button>
                </>
              )}
              {parent && (
                <Link
                  className="show-all"
                  onClick={close}
                  to={parent?.primaryRoute?.path}
                >
                  <span>
                    {t('Show all')} {parent?.name}
                  </span>
                  <SVG name={'Chevron_right'} />
                </Link>
              )}
              {categories?.map(cat =>
                cat?.hasSubcategories && cat?.subcategories ? (
                  <button
                    className="menu-btn"
                    key={`btn-${cat?.id}`}
                    onClick={() => setActiveId(cat?.id)}
                  >
                    <span>{cat?.name}</span>
                    <SVG name={'Chevron_right'} />
                  </button>
                ) : (
                  <React.Fragment key={cat?.id}>
                    {cat?.name?.toLowerCase() !== 'menu content' && (
                      <Link
                        className="anze"
                        onClick={close}
                        to={cat?.primaryRoute?.path}
                        key={`link-${cat.id}`}
                      >
                        <span>{cat?.name}</span>
                      </Link>
                    )}
                  </React.Fragment>
                )
              )}

              {/* {routes && (
                <div className="sub-menu">
                  <Link onClick={close} to={'/life-guide'}>
                    <span>{t('Life Guide')}</span>
                  </Link>
                  <Link onClick={close} to={'/customer-service'}>
                    <span>{t('Customer service')}</span>
                  </Link>
                  <Link onClick={close} to={'/customer-service'}>
                    <div>
                      <SVG name={'Heart'} />
                      <span>{t('Favourites')}</span>
                    </div>
                  </Link>
                  <Link onClick={close} to={routes?.stores?.path}>
                    <div>
                      <SVG name={'Pin'} className={'bigger'} />
                      <span>{t('Find store')}</span>
                    </div>
                  </Link>
                </div>
              )} */}
            </div>
          </FlyoutContentWrapper>

          {categories?.map(cat =>
            cat?.hasSubcategories && cat?.subcategories ? (
              <FlyoutMenuLayout
                key={`cat-${cat?.id}`}
                {...rest}
                close={close}
                parent={{ ...cat, parent: parent }}
                goBack={backHandler}
                categories={cat?.subcategories}
                active={cat?.id === activeId}
              />
            ) : null
          )}
        </>
      )}
    </>
  );
};
