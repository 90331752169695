import { css } from 'linaria';
import { theme } from '../../../Theme';

export const mobileHeaderStyle = css`
  width: 100%;
  background-color: ${theme.colors.white};
  z-index: 1000;
  display: block;
  position: sticky;
  top: 0;

  .logo-and-icons {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    .left {
      svg {
        color: ${theme.colors.black};
        height: 45px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 1rem;

      svg {
        height: 22px;
        width: auto;
        color: ${theme.colors.black};
      }
      .Menu {
        height: 20px;
      }
    }
  }

  .header-search {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border: 1px solid ${theme.colors.secondaryBackground};
    border-left: none;
    border-right: none;
  }
`;
