import { css } from 'linaria';
import { theme } from '../../../Theme';

export const footerContentStyle = css`
  width: 100%;
  padding: 3.5rem 0;

  ${theme.below.lg} {
    padding: 0px 0px 28px 0px;
  }

  .max-width {
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    max-width: 1200px;
    margin: 0 auto;

    ${theme.below.xl} {
      padding: 0 2rem;
    }

    ${theme.below.lg} {
      padding: 0px;
      flex-direction: column-reverse;
      gap: 40px;
    }

    .full-grid {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(6, auto);
      grid-column-gap: 120px;
      grid-row-gap: 20px;
      color: ${theme.colors.lightgrey};

      ${theme.below.xl} {
        grid-template-columns: repeat(6, auto);
        grid-column-gap: 40px;
      }

      ${theme.below.lg} {
        display: flex;
        align-items: revert;
        flex-direction: column-reverse;
        grid-column-gap: 0px;
        grid-row-gap: 14px;
      }

      .footer-logo {
        grid-area: 1 / 1 / 2 / 2;
        width: 250px;
        margin: 10px 0;

        img {
          height: 71px;
          width: 300px;
          object-fit: contain;

          ${theme.below.xl} {
            width: 250px;
          }

          ${theme.below.lg} {
            width: 100% !important;
            padding-left: 21px;
          }
        }
      }

      .text-info {
        max-width: 405px;
        grid-area: 1 / 2 / 2 / 3;

        ${theme.below.lg} {
          padding: 15px 0px 3px 0px;
          display: flex;
          flex-direction: column;
          padding: 0 15px 0 21px;
          /* align-items: center;
          text-align: center; */
        }

        h1 {
          margin: 0 0 9px 0;
          font-size: 38px;
          font-weight: 700;
          line-height: 1.2;
          margin-bottom: 1rem;
        }
        p {
          font-size: 14px;
          font-weight: 300;
          ${theme.below.lg} {
          }
        }
      }

      .column-grid {
        grid-area: 1 / 4 / 2 / 7;
        display: grid;
        grid-template-columns: 120px 150px;
        grid-template-rows: auto;
        grid-column-gap: 80px;
        grid-row-gap: 0px;
        direction: ltr;
        text-align: left;

        a {
          font-weight: 300;
        }

        ${theme.below.lg} {
          direction: rtl;
          display: flex;
          flex-direction: column;
        }

        .accordion-details summary {
          flex-direction: row;
          ${theme.below.lg} {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 4px;
            text-transform: uppercase;
          }
        }
        .accordion-details {
          ${theme.below.lg} {
            border-bottom: 1px solid #565e73;
            padding: 15px 21px 10px 21px;
          }
        }
      }
    }
    .follow-us {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin-left: 21px;
      margin-bottom: 10px;
    }
  }
`;
