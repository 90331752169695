import { css } from 'linaria';
import { theme } from '../../Theme';

css`
  :global() {
    .content-positioner-container {
      .content-pre-title {
        ${theme.fonts.desktop.h4}
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        width: 100%;
        ${theme.below.lg} {
          font-size: 16px;
        }
      }

      .content-title {
        ${theme.fonts.desktop.h2};
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 10px;
        font-weight: 700;

        ${theme.below.lg} {
          font-size: 28px;
          line-height: 34px;
        }

        &.color-box {
          font-size: 32px;
          line-height: 40px;
        }
        &.hero {
          font-size: 48px;
          line-height: 48px;
        }
      }

      .content-text {
        margin-bottom: 10px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }

      .cta {
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }
  }
`;
