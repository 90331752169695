import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { smallCaps } from '../ui/Headings';
import { activeSegment, smallSection } from './UI/Form';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

import { theme } from '../Theme';

export const StyledTrendButton = styled(TrendButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: 45px;
  padding: 14px 0;
  border-radius: 30px;
  text-transform: uppercase;
  background: ${theme.colors.darkgrey};
  ${theme.fonts.desktop.a}
  letter-spacing: 0.1em;
  margin: 0.5em 0;
  :hover {
    background: ${theme.colors.darkgrey};
  }
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.darkgrey};
  text-align: center;
  font-size: 12px;
  font-weight: 300;

  :hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

export const LogInPageFormWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => props.direction};
  width: 442px;
  height: ${props => props.size};
  margin: 0 auto;
  background: ${theme.colors.white};

  ${theme.below.md} {
    width: 350px;
  }
`;

export const LoginHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.marginTop};
  margin-bottom: 17px;

  h1 {
    font-size: 43px !important;
    font-weight: 700;
  }

  ${theme.below.lg} {
    margin-top: 5rem;
  }
  ${theme.below.md} {
    margin-top: 3rem;
  }
`;

function LogInPage() {
  const { routes } = useShopConfig();
  const [formWrapperSize, setFormWrapperSize] = useState('350px');
  return (
    <div className="grayBg">
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <>
            <LoginHeader marginTop={globalError ? '8rem' : '10rem'}>
              <h1 className="login-header" style={{ marginBottom: 0 }}>
                {t('My Pages')}
              </h1>
            </LoginHeader>
            <LogInPageFormWrapper
              size={globalError ? '500px' : '350px'}
              direction={'row'}
            >
              <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>

              <section className={cx(smallSection)}>
                <Input type="email" name="email" label={t('E-mail address')} />
                <Input type="password" name="password" label={t('Password')} />

                {globalError && (
                  <GlobalError style={{ marginBottom: '2em' }}>
                    {setFormWrapperSize('500px')}
                    {globalError}
                  </GlobalError>
                )}

                <section className={actionWrapper}>
                  <StyledTrendButton
                    type="submit"
                    disabled={!isValid}
                    loading={isSubmitting}
                    loadingText={t('Hold on a moment...')}
                  >
                    {t('Log in')}
                  </StyledTrendButton>
                  <TextLink
                    to={routes.forgotPassword.path}
                    className="forgot-password-link"
                  >
                    {t('Forgot password')}?
                  </TextLink>
                  <TextLink to="/application-form" className="signup-link">
                    {t('Not yet a customer?')} {t('Apply here')}
                  </TextLink>
                </section>
              </section>
            </LogInPageFormWrapper>
          </>
        )}
      </LogInFormProvider>
    </div>
  );
}

export default LogInPage;
