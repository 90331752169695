//Library Imports
import {
  LinkRowItem,
  Spacer,
  StyledBoxRow,
  Title,
  StyledHtmlRow,
  StyledHtmlRowItem,
  StyledLinkRow,
  Hero,
  StyledCampaignBar,
  StyledCampaignBarItem,
  BoxRowImageItem,
  BoxRowColorItem,
  CampaignBarItem
} from 'r3-lib/dist/r3lib';

// //Local Imports
import { StyledProductRow } from './ProductRow';
import { ProductRowItem } from './ProductRowItem';
import { StyledCategoryRow } from './CategoryRow';
import { CartContent } from './CartContent/CartContent';
import { CartTextItem } from './CartTextItem/CartTextItem';
import { CartImageItem } from './CartImageItem/CartImageItem';
import { FooterContent } from '../Layout/Footer/FooterContent/FooterContent';
import {
  FooterLinksColumn,
  FooterLinksColumnItem
} from '../Layout/Footer/FooterLinksColumn/FooterLinksColumn';
import { PaymentMethodsRow } from '../Layout/Footer/PaymentMethodsRow/PaymentMethodsRow';
import { SocialMediaRow } from './SocialMediaRow/SocialMediaRow';

export const allContentComponents = {
  HERO: Hero,
  SPACER: Spacer,
  BOXROW: StyledBoxRow,
  BOXROWIMAGEITEM: BoxRowImageItem,
  TITLE: Title,
  HTMLROW: StyledHtmlRow,
  RAWHTML: StyledHtmlRowItem,
  WYSIWYG: StyledHtmlRowItem,
  PRODUCTROW: StyledProductRow,
  PRODUCTROWITEM: ProductRowItem,
  CATEGORYROW: StyledCategoryRow,
  LINKROW: StyledLinkRow,
  LINKROWITEM: LinkRowItem,
  BOXROWCOLOR: StyledBoxRow,
  BOXROWCOLORITEM: BoxRowColorItem,
  ONEOFEACH: StyledBoxRow
};

export const footerComponents = {
  'FOOTER-1': FooterContent,
  'FOOTER-2-0-COLUMN': FooterLinksColumn,
  'FOOTER-2-1-COLUMNITEM': FooterLinksColumnItem,
  PAYMENTMETHODS: PaymentMethodsRow,
  SOCIALMEDIALINKS: SocialMediaRow
};

export const uspComponents = {};

export const seoComponents = {
  SEOROW: StyledHtmlRow,
  RAWHTML: StyledHtmlRowItem,
  WYSIWYG: StyledHtmlRowItem
};

export const cartComponents = {
  CARTCONTENT: CartContent,
  CARTTEXTITEM: CartTextItem,
  CARTIMAGEITEM: CartImageItem,
  PAYMENTMETHODS: PaymentMethodsRow
};

export const campaignbarComponents = {
  CAMPAIGNBAR: StyledCampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem
};
