import { css } from 'linaria';
import { theme } from '../../../Theme';

export const footerLinksColumnStyle = css`

.footer-links-column {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

  h4,
  summary {
    margin-bottom: 0.30rem;
    ${theme.fonts.desktop.p2}
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  a, button, div {
    background: transparent;
    line-height: 160%;
    text-decoration: none;
    letter-spacing: 0.05em;
    color: inherit;
    ${theme.fonts.desktop.p1}
    ${theme.below.lg} {
    ${theme.fonts.mobile.p1}
    }
  a {
    line-height: 190%;
  }
  }
`;
