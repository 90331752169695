import { css } from 'linaria';
import { theme } from '../../../Theme';

export const searchResultProductCardStyle = css`
  margin-bottom: 20px;
  margin-right: 40px;
  .link-evaluator {
    display: flex;
    gap: 20px;
    text-decoration: none;

    [data-flight-image-container] {
      width: 75px !important;
      height: 75px !important;
    }

    .product-information {
      color: ${theme.colors.black};
      h4 {
        ${theme.fonts.desktop.p1}
        line-height: 120%;
        font-weight: ${theme.fontWeights.bold};
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }
  }
`;
