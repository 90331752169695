import React, { useRef } from 'react';
import { styled } from 'linaria/react';
import 'swiper/swiper-bundle.min.css';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import { ReactComponent as Left } from '../../../svg/left.svg';
import { ReactComponent as Right } from '../../../svg/right.svg';
import { cx, css } from 'linaria';

const SharedSwiperWrapper = styled('div')`
  width: 100%;
  max-width: 100vw;
  position: relative;
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #dbdfe0;
    top: 50%;
    :after {
      display: none;
    }
  }
  .no-swiper-btn {
    display: none;
  }

  .swiper-button-prev {
    left: 10px;
    svg {
      height: 14px;
    }
  }

  .swiper-button-next {
    right: 30px;
    svg {
      height: 14px;
    }
  }

  .swiper-pagination {
    display: flex;
    /* padding: 20px 1rem; */
    background: ${theme.baseColors.white};
    bottom: 0;
  }

  .swiper-pagination-bullet {
    flex: 1;
    height: 3px;
    background: ${theme.baseColors.white};
    opacity: 1;
    border-radius: 0;
    margin: 0 !important;

    &.swiper-pagination-bullet-active {
      background: ${theme.baseColors.black};
    }
  }

  .swiper-slide {
    max-width: 250px;
  }
`;

export const SharedSwiper = ({
  children,
  loop = true,
  full,
  shouldAutoplay,
  gap = 0,
  ...props
}) => {
  const swiperRef = useRef(null);
  const desktopSlideCheckValue = 4;
  const mobileSlideCheckValue = 2;

  const desktopSlidesPerView =
    children?.length < desktopSlideCheckValue
      ? children?.length
      : desktopSlideCheckValue;
  const mobileSlidesPerView =
    children?.length < mobileSlideCheckValue
      ? children?.length
      : mobileSlideCheckValue;

  const handlePrevClick = () => {
    swiperRef?.current?.swiper?.slidePrev();
  };
  const handleNextClick = () => {
    swiperRef?.current?.swiper?.slideNext();
  };

  return (
    <>
      <SharedSwiperWrapper
        className="shared-swiper-wrapper"
        length={children?.length ?? 1}
      >
        <Above breakpoint="lg">
          {matches => {
            const slideCheckValue = matches
              ? desktopSlideCheckValue
              : mobileSlideCheckValue;
            const isSlideCheckOrLess = children?.length <= slideCheckValue;

            return (
              <>
                <Swiper
                  className="shared-swiper"
                  ref={swiperRef}
                  effect={'cover'}
                  grabCursor={shouldAutoplay ? false : true}
                  allowTouchMove={shouldAutoplay ? false : true}
                  threshold={2} // Resolves issue when swiper slides instead of click
                  centeredSlides={false}
                  loop={loop}
                  autoplay={shouldAutoplay && { delay: 5000 }}
                  speed={shouldAutoplay && 800}
                  spaceBetween={gap} // in px
                  slidesPerView={
                    full
                      ? 1
                      : matches
                      ? desktopSlidesPerView
                      : mobileSlidesPerView
                  }
                  modules={
                    (shouldAutoplay || props?.navigation) && [
                      Autoplay,
                      Pagination
                    ]
                  }
                  {...props}
                >
                  {children?.map((child, index) => (
                    <SwiperSlide
                      key={'shared-swiper-slide-' + index}
                      className="swiper-slide"
                    >
                      {child}
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div
                  className={
                    isSlideCheckOrLess ? 'no-swiper-btn' : 'swiper-button-prev'
                  }
                  onClick={() => handlePrevClick()}
                >
                  <Left />
                </div>
                {(loop || children.length > slideCheckValue) && matches && (
                  <div
                    className={
                      isSlideCheckOrLess
                        ? 'no-swiper-btn'
                        : 'swiper-button-next'
                    }
                    onClick={() => handleNextClick()}
                  >
                    <Right />
                  </div>
                )}
              </>
            );
          }}
        </Above>
      </SharedSwiperWrapper>
    </>
  );
};
