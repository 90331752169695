interface ContentProp {
  name: string;
  // type: string;
  value: any;
  // valueType: string;
  // __typename: string;
}

export const injectMultiplePropsToCEChild = (child, props: [ContentProp]) => {
  let newprops = [];
  props?.forEach(prop => {
    newprops.push(prop);
  });

  return { ...child, properties: [...child?.properties, ...newprops] };
};

// export const injectMultiplePropsToCEItem = (item, props: [ContentProp]) => {

//   let newprops = [];
//   props?.forEach(prop => {
//     newprops.push(prop);
//   });

//   return { ...child, properties: [...child?.properties, ...newprops] };
// };
