import React from 'react';
import { FlyoutContentWrapper } from './FlyoutMenuLayout';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import { ScrollSnapper } from 'r3-lib/dist/r3lib';
import { SVG } from '../../../assets/SVG';
import { theme } from '../../Theme';
import { ProductCard } from '../../CategoryPage/ProductCard';

export const FlyoutSearchLayout = ({
  searchValue,
  categories,
  products,
  close,
  routes,
  ...rest
}) => {
  return (
    <FlyoutContentWrapper active={true}>
      <div className="categories">
        <>
          <button onClick={close} className="backbutton">
            <span>
              {t(`You searched for "${searchValue}"`, {
                searchValue
              })}
            </span>

            <SVG name={'Chevron_right'} />
          </button>
          {categories?.length === 0 && products?.length === 0 && (
            <Link
              onClick={close}
              to={`${routes?.search?.path}/?term=${searchValue}`}
              style={{
                background: theme.colors.lightgrey,
                height: 'auto',
                padding: '14px'
              }}
            >
              <span
                style={{
                  fontSize: theme.fontSizes.mobile.p2
                }}
              >
                {t('No matches found, try full search or alternate your query')}
              </span>
            </Link>
          )}
          <Link
            className="show-all"
            onClick={close}
            to={`${routes?.search?.path}/?term=${searchValue}`}
          >
            <span>{t('Show full result')}</span>
            <SVG name={'Chevron_right'} />
          </Link>

          {categories?.length > 0 && (
            <>
              {categories?.slice(0, 5)?.map(category => {
                return (
                  <Link
                    onClick={close}
                    to={category?.primaryRoute?.path}
                    key={`link-${category.id}`}
                  >
                    <span>{category?.name}</span>
                  </Link>
                );
              })}
            </>
          )}

          {products?.length > 0 && (
            <>
              <Link
                className="show-all"
                onClick={close}
                to={`${routes?.search?.path}/?term=${searchValue}`}
              >
                <span>{t('Show all products')}</span>
                <SVG name={'Chevron_right'} />
              </Link>
              <div style={{ height: '20px' }} />
              <ScrollSnapper childGap="0px" childWidth="85%">
                {products?.map((product, index) => {
                  return (
                    <div
                      key={'searched-' + product?.articleNumber + '-' + index}
                    >
                      <ProductCard product={product} onClick={close} />
                    </div>
                  );
                })}
              </ScrollSnapper>
            </>
          )}
        </>
      </div>
    </FlyoutContentWrapper>
  );
};
