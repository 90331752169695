import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import { TrendButton } from '@jetshop/ui/Button';
import { css, cx } from 'linaria';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { SmallCaps } from '../../ui/Headings';
import { activeSegment } from '../UI/Form';
import { useSignupValidation } from './useSignupValidation';
import { StyledTrendButton } from '../LogInPage';
import { theme } from '../../Theme';

const sectionStyle = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  width: 452px;
  overflow: hidden;

  ${theme.below.sm} {
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    width: 350px;
    height: auto;
  }
`;

const registerButton = css`
  margin-top: 1em;
  ${theme.below.sm} {
    margin-bottom: 3em;
  }
`;

export default function LoginFields(props) {
  const { fields } = useLoginFields();
  const { isSignupError, validationDetails, isSubmitting } = useSignupForm();
  const validationMessage = useSignupValidation(validationDetails);
  function focusSection() {
    if (!props.isActive) props.setActiveSection('loginFields');
  }

  return (
    <section
      className={cx(
        'section',
        props.isActive ? 'active' : null,
        sectionStyle,
        activeSegment
      )}
    >
      {/* <SmallCaps className="heading">{t('Email & Password')}</SmallCaps> */}

      {isSignupError && (
        <GlobalError style={{ marginBottom: '2em' }}>
          {validationMessage}
        </GlobalError>
      )}

      {fields?.map(field => {
        return <Input {...field.inputProps} onFocus={focusSection} />;
      })}

      <StyledTrendButton
        className={registerButton}
        data-testid="sign-up-button"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? t('Hold on a moment…') : t('Send application')}
      </StyledTrendButton>
    </section>
  );
}
