import { styled } from 'linaria/react';

export default styled('main')`
  background: #ffffff;
  flex: 1 1 auto;
  padding-bottom: 2rem;
  &:has(.grayBg) {
    background: #f6f6f6;
  }
`;
