import React from 'react';
import { useQuery } from 'react-apollo';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { StyledDesktopHeader } from './DesktopHeader/DesktopHeader';
import { Above } from '@jetshop/ui/Breakpoints';
// import { StyledMobileHeader } from './MobileHeader/MobileHeader';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Notifications } from '../Notifications';
import { StyledMobileHeader } from './MobileHeader/MobileHeader';
import cartQuery from '../../Cart/queries/cartQuery.gql';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import { useProductList } from '@jetshop/core/hooks/ProductList';
// import { QuickBuyFlyout } from '../QuickBuy/QuickBuyFlyout';
// import { QuickBuyTrigger } from '../QuickBuy/QuickBuyTrigger';

const Header = () => {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const listId = null;
  const { routes } = useShopConfig();
  const cartData = useCart(cartQuery);
  const { count } = useProductList(listId);

  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <StyledDesktopHeader
              categories={result?.data?.categories}
              routes={routes}
              cartData={cartData}
              favouritesCount={count}
            />
          ) : (
            <StyledMobileHeader
              categories={result?.data?.categories}
              routes={routes}
              cartData={cartData}
              favouritesCount={count}
            />
          )
        }
      </Above>
      {/* <QuickBuyFlyout />
      <QuickBuyTrigger /> */}
      <Notifications />
    </>
  );
};

export default Header;
