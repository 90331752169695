//Old HeaderHeightContext.js
// import React from 'react';
// import { useScrollPosition } from '../hooks/useScrollPosition';

// const HeaderHeightContext = React.createContext();

// const HeaderHeightProvider = ({ headerId, children }) => {
//   const scroll = useScrollPosition();

//   const [topPos, setTopPos] = React.useState(0);
//   const docCheck = typeof document !== 'undefined';

//   React.useEffect(() => {
//     let tempPos = 0;
//     if (docCheck) {
//       const topBarHeight = document.getElementById(headerId)?.clientHeight ?? 0;
//       if (scroll < topBarHeight) {
//         tempPos -= scroll;
//       } else if (scroll > topBarHeight) {
//         tempPos -= topBarHeight;
//       }
//       setTopPos(tempPos);
//     }
//   }, [docCheck, scroll]);

//   const value = { headerHeight: topPos };

//   return (
//     <HeaderHeightContext.Provider value={value}>
//       {children}
//     </HeaderHeightContext.Provider>
//   );
// };

// export { HeaderHeightContext, HeaderHeightProvider };

import React from 'react';
// import { useScrollPosition } from '../hooks/useScrollPosition';

const HeaderHeightContext = React.createContext();

const HeaderHeightProvider = ({ headerIds, children }) => {
  //COMMENT: note the hardcoded values, may need to be adjusted
  const docCheck = typeof document !== 'undefined';
  const topBarHeight = docCheck
    ? document.getElementById(headerIds[0])?.clientHeight ?? 63
    : 0;
  const campaignBarHeight = docCheck
    ? document.getElementById(headerIds[1])?.clientHeight ?? 0
    : 0;
  const menubarHeight = docCheck
    ? document.getElementById(headerIds[2])?.clientHeight ?? 38
    : 0;

  // console.log('topBarHeight', topBarHeight);
  // console.log('campaignBarHeight', campaignBarHeight);
  // console.log('menubarHeight', menubarHeight);

  const totalHeight = topBarHeight + campaignBarHeight + menubarHeight;

  const value = { headerHeight: totalHeight };

  return (
    <HeaderHeightContext.Provider value={value}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

export { HeaderHeightContext, HeaderHeightProvider };
