import { css } from 'linaria';

css`
  :global() {
    .box-row-image-item {
      position: relative;
      flex: 1;

      .box-image-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
      }
      .content-positioner {
        padding: 10px 20px;
      }
      .content-title {
        font-size: 16px;
        line-height: 30px;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
    }
  }
`;
