import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Cart } from '../../../svg/Cart.svg';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { QuickBuyContext } from './QuickBuyContext';
import { useTrackProductAdd } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { theme } from '../../Theme';

const QuickBuyButtonWrapper = styled('button')`
  background: ${theme.colors.accent};
  height: 34px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:hover {
    background: ${theme.colors.variant.accent};
  }

  svg {
    color: white;
    width: 12px;
    height: 14px;
    margin-right: 1px;
    path {
      fill: white;
    }
  }

  &.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 30px !important;
    width: 30px !important;
    padding: 0px !important;
    margin-top: 0 !important;
    margin-right: 0px !important;
    svg {
      height: 14px !important;
      width: 14px !important;
      margin-bottom: 1px;
    }
  }
`;

/**
 * The QuickBuyButton function renders a button that when clicked, either adds product to cart or displays the QuickBuyFlyout component.
 *
 * @param {object} product Pass the product details
 * @param {number} qty Set the default quantity of the product
 *
 * @return A button that can add the product to the cart
 *
 * @docauthor Anders Zetterström @ R3
 */
export const QuickBuyButton = ({ icon, product, qty = 1 }) => {
  const trackAddProductVariant = useTrackProductAdd(product);

  const trackAddToCart = () => {
    trackAddProductVariant(product, qty);
  };
  const { addProduct } = useAddToCart(trackAddToCart);
  const { setQuickBuyProduct, setQuickBuyQty } = useContext(QuickBuyContext);

  if (!product?.stockStatus?.buyable) {
    return null;
  }

  // console.log('product', product);

  return (
    <div className="quick-buy-button">
      {product?.variants?.values?.length > 0 ? (
        <DrawerTrigger preventOverflow={true} id="quick-buy-drawer">
          {drawer => (
            <QuickBuyButtonWrapper
              onClick={e => {
                // Disables link click
                e.preventDefault();
                setQuickBuyProduct(product);
                setQuickBuyQty(qty);
                drawer.showTarget();
              }}
            >
              <Cart />
            </QuickBuyButtonWrapper>
          )}
        </DrawerTrigger>
      ) : (
        <QuickBuyButtonWrapper
          className={
            !icon && 'Lägg i varukorg' ? 'quick-buy-text' : icon && 'icon'
          }
          onClick={e => {
            // Disables link click
            e.preventDefault();
            addProduct(product, qty);
          }}
        >
          <span>
            {icon && <Cart />}
            {!icon && 'Lägg i varukorg'}
          </span>
        </QuickBuyButtonWrapper>
      )}
    </div>
  );
};
