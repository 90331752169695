import { css } from 'linaria';
import { theme } from '../../Theme';

export const searchBarStyle = css`
  display: flex;
  align-items: center;
  gap: 10px;

  ${theme.below.lg} {
    width: 100%;
  }

  svg {
    height: 22px;
    color: ${theme.colors.black};

    ${theme.below.lg} {
      height: 17px;
    }
  }

  .search-bar-input {
    display: flex;
    flex-direction: column;
    height: 22px;
    ${theme.below.lg} {
      width: 100%;
    }
  }

  input {
    margin-top: 3px;
    border: none;
    width: 290px;
    ${theme.fonts.desktop.p1}

    ${theme.below.lg} {
      ${theme.fonts.mobile.p2}
      width: 100%;
    }

    :focus {
      outline: none;
      border-bottom: 1px solid ${theme.colors.black}aa;
    }
  }

  input::placeholder {
    color: ${theme.colors.black};
    opacity: 1;
  }
`;

export const searchBarCancelButtonStyle = css`
  &input,
  input {
    -webkit-appearance: none;
    ::-webkit-search-cancel-button {
      -webkit-appearance: none;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z'/%3E%3C/svg%3E"); /* url(./svg/MenuClose.svg); */
      height: 16px;
      width: 16px;
      display: block;
      cursor: pointer;
    }
  }
`;
