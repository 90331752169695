import React, { useState } from 'react';

import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Currency } from '@jetshop/ui/Price/Currency';
import { theme } from '../../Theme';
import { CartStatus } from '../Cart/CartStatus';
import { CartProductCard } from '../Cart/CartProductCard/CartProductCard';
import { Price } from '@jetshop/ui/Price';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { FreeShipping } from '../Cart/FreeShipping';
import { CTA, Loader, useModalManagement } from 'r3-lib/dist/r3lib';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import { DynamicCategoryRenderer } from '../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { cartComponents } from '../../ContentEditor/ContentComponents';

export const FlyoutContentWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${theme.colors.white};

  .flyout-cart-layout-scroll-title-row {
    padding: 30px 20px;
  }
  .flyout-cart-layout-items {
    padding: 0 20px;
  }
  .cart-lower {
    padding: 20px 20px;
  }

  .flyout-cart-layout-scroll {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
    .flyout-cart-layout-scroll-title-row {
      display: flex;
      justify-content: space-between;
      h4 {
        ${theme.fonts.mobile.a}
        text-transform: uppercase;
        letter-spacing: 0.2em;
      }

      p  {
        font-size: ${theme.fontSizes.desktop.p1};
        font-weight: ${theme.fontWeights.light};
        line-height: 21px;
        align-self: flex-end;
      }
    }

    .cart-lower {
      color: ${theme.colors.darkgrey};
      background: ${theme.colors.lightgrey};

      .summary {
        .discounts {
          margin-bottom: 16px;
          h4 {
            font-size: ${theme.fontSizes.mobile.h4};
            font-weight: ${theme.fontWeights.medium};
          }
          .discount,
          .free-shipping {
            display: flex;
            justify-content: space-between;
            font-size: ${theme.fontSizes.desktop.p1};
            line-height: 24px;
          }
        }

        .cart-total {
          .total,
          .vat {
            display: flex;
            justify-content: space-between;
            font-weight: ${theme.fontWeights.medium};
          }

          .total {
            font-size: ${theme.fontSizes.mobile.h3};
            font-weight: ${theme.fontWeights.medium};
          }
          .vat {
            font-size: ${theme.fontSizes.desktop.p1};
            font-weight: ${theme.fontWeights.light};
            line-height: 250%;
          }
          .vat-label {
            font-weight: ${theme.fontWeights.medium};
          }
        }

        .cart-buttons {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .cta {
            width: 100%;
            height: 45px;
            font-size: ${theme.fontSizes.desktop.p1};
            font-weight: ${theme.fontWeights.regular};
            text-transform: uppercase;
            border-radius: 3px;

            .loader {
              height: 12px;
              width: 60px;
            }
          }
        }
      }

      .cart-payment-information {
        margin-top: 20px;

        .payment-method-row {
          padding-top: 5px;
        }

        .footer-payment-methods {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 30px;
          align-items: center;
          justify-content: center;
          margin-top: 0px;
          gap: 20px;

          .icon {
            height: 30px;
            max-height: 40px;
            width: auto;
            max-width: 100px;

            &.Klarna {
              height: 15px;
              order: 4;
            }
            &.Visa {
              height: 14px;
              order: 2;
            }
            &.Mastercard {
              height: 35px;
              order: 1;
            }
            &.Swish {
              height: 30px;
              order: 3;
            }
          }
        }
      }
    }
  }

  .cta {
    border-radius: 3px;
  }

  .outline {
    border: 1px solid #343741;
    background: transparent;
    div {
      color: #343741;
    }
    :hover {
      opacity: 0.7;
    }
  }
`;

export const FlyoutCartLayout = ({ result, cart }) => {
  const [checkoutBtnClicked, setCheckoutBtnClicked] = useState(false);
  const { closeAll } = useModalManagement();

  const items = cart?.items;
  const totalQuantity = cart?.totalQuantity;
  const checkoutUrl = cart?.externalCheckoutUrl;

  const discounts = cart?.aggregatedDiscounts;
  const { pricesIncVat } = useChannelSettings();
  const track = useTracker();

  return (
    <FlyoutContentWrapper>
      <CartStatus result={result}>
        <div className="flyout-cart-layout-scroll">
          <div className="flyout-cart-layout-scroll-title-row">
            <h4>{t('Cart')}</h4>
            {totalQuantity > 0 && (
              <p>
                {totalQuantity}{' '}
                {t('{productCount, plural, =1 {Product} other {Products}}', {
                  productCount: totalQuantity
                })}
              </p>
            )}
          </div>
          <div className="flyout-cart-layout-items">
            {items?.map((item, index) => (
              <CartProductCard
                key={item?.id + index}
                item={item}
                linkClickCallback={() => {
                  closeAll();
                }}
              />
            ))}
          </div>
          <div className="cart-lower">
            <div className="summary">
              <div className="cart-total">
                <div className="total">
                  <label>
                    {t('Total excl. VAT')}
                    {/* {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')} */}
                  </label>
                  <p>{cart?.productTotal.exVat}kr</p>
                </div>
                {/* <VAT
                  className="vat"
                  total={cart?.productTotal}
                  pricesIncVat={pricesIncVat}
                /> */}
                <div className="cart-buttons">
                  <CTA
                    className={'accent'}
                    clickCallback={() => {
                      setCheckoutBtnClicked(true);
                      track(
                        trackCartCheckoutEvent({
                          cart: result?.data?.cart,
                          callback: () => {
                            window.location = checkoutUrl;
                          }
                        })
                      );
                    }}
                  >
                    {checkoutBtnClicked ? (
                      <Loader color="white" />
                    ) : (
                      t('Go to checkout')
                    )}
                  </CTA>
                  <CTA className={'outline'} clickCallback={() => closeAll()}>
                    {t('Continue shopping')}
                  </CTA>
                </div>
              </div>
              <div className="cart-payment-information">
                <DynamicCategoryRenderer
                  categoryId={170} // TODO: get from env
                  rendererComponents={cartComponents}
                />
              </div>
            </div>
          </div>
        </div>
      </CartStatus>
    </FlyoutContentWrapper>
  );
};

const VAT = ({ total, pricesIncVat, ...rest }) => {
  return (
    <div {...rest}>
      <label className="vat-label">
        {pricesIncVat ? t('of which VAT') : t('+ VAT')}
      </label>
      <div>
        <Currency value={total?.vat} />
      </div>
    </div>
  );
};
