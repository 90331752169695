import { css } from 'linaria';
import { theme } from '../../Theme';

// Use linaria css to style the component.
// All styling should be placed in :global().
// Begin all styling with root class name of component.

css`
  :global() {
    .cta {
      height: ${theme.button.desktop.height} !important;
      width: fit-content;
      /* padding: ${theme.button.desktop.padding} !important; */
      border-radius: ${theme.button.desktop.borderRadius};
      cursor: pointer;
      background: black;
      padding: 0 20px;
    

      a,
      div {
        color: white;
      }

      .link-evaluator {
        display: flex;
        justify-content: center;
        align-items: center;

        letter-spacing: 0.2em;
        font-size: 11px;
        font-weight: ${theme.fontWeights.bold};
        line-height: 1.3;
        ${theme.below.sm} {
          font-size: 9px;
          padding: 0;
        }
      }

      &.accent {
        background: ${theme.colors.accent};
        a,
        div {
          color: ${theme.colors.white};
        }
        :hover,
        :active {
          background: ${theme.colors.variant.accent};
        }
      }

      &.dark {
        background: ${theme.colors.darkgrey};
        a,
        div {
          color: ${theme.colors.white};
        }
        :hover,
        :active {
          background: ${theme.colors.variant.black};
        }
      }

      &.light {
        background: ${theme.colors.white};
        a,
        div {
          color: ${theme.colors.black};
        }
        :hover,
        :active {
          background: ${theme.colors.variant.white};
        }
      }

      &.accent_outline {
        background: transparent;
        border: 1px solid ${theme.colors.accent};
        a,
        div {
          color: ${theme.colors.accent};
        }
        :hover,
        :active {
          background: ${theme.colors.accent};
          a,
          div {
            color: ${theme.colors.white};
          }
        }
      }

      &.light_outline {
        background: transparent;
        border: 1px solid ${theme.colors.white};
        a,
        div {
          color: ${theme.colors.white};
        }
        :hover,
        :active {
          background: ${theme.colors.white};
          a,
          div {
            color: ${theme.colors.black};
          }
        }
      }

      &.dark_outline {
        background: transparent;
        border: 1px solid ${theme.colors.black};
        a,
        div {
          color: ${theme.colors.black};
        }
        :hover,
        :active {
          background: ${theme.colors.black};
          a,
          div {
            color: ${theme.colors.white};
          }
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
`;
