import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { css } from 'linaria';
import { ReactComponent as HeartSVG } from '../../svg/HeartSVG.svg';
import { theme } from '../Theme';

const favourite = css`
  button {
    background: transparent;

    svg {
      width: 18px;
      height: 18px;
      color: ${theme.colors.black};

      fill: none;

      &.active {
        fill: currentColor;
      }
    }
  }
  .favourites-button {
    font-family: ${theme.fonts.primaryFontFamily};
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    height: 34px;
    padding: 0 10px;
    background: #282828;

    svg {
      margin-right: 10px;
      path {
        fill: #ffffff;
      }

      &.active {
        fill: white;
      }
    }
  }
`;

export function Favourite({ isButton, product, variant, listId, ...rest }) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList(listId);

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber,
      productName: product.name
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <div className={favourite} {...rest}>
      <button
        onClick={toggleWithoutBubble}
        aria-label="Add to favourites"
        className={isButton && 'favourites-button'}
      >
        {isButton ? (
          <span>
            {/* <HeartSVG className={isInList ? 'active' : 'inactive'} /> */}
            {isInList ? 'Ta bort från inköpslista' : 'Lägg i inköpslista'}
          </span>
        ) : (
          <HeartSVG className={isInList ? 'active' : 'inactive'} />
        )}
      </button>
    </div>
  );
}
