import { Helmet } from 'react-helmet-async';
import React from 'react';

/**
 * The FaviconHelper function is a React component that renders the appropriate
 * favicon. It takes in one parameter:
 * faviconFolderPath which are a string that specify the path to the folder containing
 * all of our favicons.
 *
 * @param {string} faviconFolderPath the path to the favicon folder
 * @param {string} title Presentable title
 *
 * @return A helmet component that contains the following tags:
 *
 * @docauthor Anders Zetterström
 */
export const FaviconHandler = ({ faviconFolderPath, title }) => {
  const bgColor1 = '#ffffff';
  // const bgColor2 = '#d1e0d7';

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${faviconFolderPath}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${faviconFolderPath}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${faviconFolderPath}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${faviconFolderPath}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${faviconFolderPath}/safari-pinned-tab.svg`}
        color={bgColor1}
      />
      <link rel="shortcut icon" href={`${faviconFolderPath}/favicon.ico`} />
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="application-name" content={title} />
      <meta name="msapplication-TileColor" content={bgColor1} />
      <meta name="theme-color" content={bgColor1} />
    </Helmet>
  );
};
