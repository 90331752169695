import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import React from 'react';

export const FlyoutTrigger = ({ triggerId, children }) => {
  const topPosition = '20px';
  return (
    <DrawerTrigger
      preventOverflow={true}
      id={triggerId}
      coverStyles={{
        top: topPosition,
        background: 'rgba(0,0,0,0.5)'
      }}
    >
      {drawer => <>{children(drawer)}</>}
    </DrawerTrigger>
  );
};
