import React from 'react';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { theme } from '../../../Theme';
import { cx } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { StyledPrice } from '../../../CategoryPage/ProductCard';
import { searchResultProductCardStyle } from './SearchResultProductCard.style';
import { usePriceDisplay } from '../../../../hooks/usePriceDisplay';
import { css } from 'linaria';

const price = css`
  .price {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #000;

    @media screen and (max-width: 420px) {
      font-size: 15px;
    }

    span {
      font-family: 'Outfit';
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000;
    }
  }
`;

export const SearchResultProductCard = ({ product, clickCallback }) => {
  const { showPrice } = usePriceDisplay();
  return (
    <div
      className={cx('search-result-product-card', searchResultProductCardStyle)}
    >
      <LinkEvaluator
        linkClickCallback={clickCallback}
        link={product?.primaryRoute?.path}
      >
        <Image
          sizes={theme.layout.imageSizes.grid}
          aspect={theme.layout.productImageAspectRatio}
          alt={product?.images?.[0]?.alt}
          src={product?.images?.[0]?.url ?? transparentDataImg}
          fillAvailableSpace={true}
          modifiedDate={product?.images?.[0]?.modifiedDate}
          critical={true}
        />
        <div className="product-information">
          {/* <h4>{product?.subName?.length > 0 ? product?.subName : 'Brand'}</h4> */}
          <p>{product?.name}</p>
          {showPrice ? (
            <>
              <div className={price}>
                <p className="price">
                  {product?.price?.exVat} kr <span>ex. moms</span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className={price}>
                <p className="price">Rek. {product?.price?.incVat} Kr</p>
              </div>
            </>
          )}
        </div>
      </LinkEvaluator>
    </div>
  );
};
