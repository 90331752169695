import React from 'react';
import { cx } from 'linaria';
import { SharedSwiper } from '../../Layout/Swiper/SharedSwiper';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { useBaseFontSize } from '../../../hooks/useBaseFontSize';
import { productRowStyle } from './ProductRow.style';
import { theme } from '../../Theme';
import { styled } from 'linaria/react';

const ProductRowOuterWrapper = styled('div')`
  width: 100%;
  margin: auto;

  h2 {
    padding: 0 20px;
    font-size: 32px;
    font-weight: 700;
  }

  .product-row-wrapper {
    margin: 30px 0;
  }

  .swiper-slide-active {
    margin-left: -10px;
  }

  .swiper-slide-prev {
    margin-left: -10px;
    margin-right: 10px;
  }

  .wrapper {
    padding-left: 20px;
  }

  ${theme.below.lg} {
    flex-direction: column;

    h2 {
      padding: 20px;
      font-size: 24px;
    }
    .product-row-wrapper {
      margin: 0;
    }

    .wrapper {
      padding-left: 20px;
    }

    .swiper-button-prev {
      display: none;
    }
  }
`;

export const ProductRow = ({ fullWidth, gap, cartPage, style, children }) => {
  const desktopChildrenLength = children?.length < 4 ? children?.length : 5;
  const mediumChildrenLength = children?.length < 2.3 ? children?.length : 2.3;
  const mobileChildrenLength = children?.length < 1.4 ? children?.length : 1.4;
  const desktopSlidesPerView = desktopChildrenLength;
  const mediumSlidesPerView = cartPage ?? mediumChildrenLength;
  const mobileSlidesPerView = cartPage ? 1.5 : mobileChildrenLength;

  const rem = useBaseFontSize();

  // TODO - Move to HelperCollection || change to px
  const remToPx = value => {
    const numString = value?.replace('rem', '');
    if (numString?.includes('px')) {
      return null;
    }
    const num = parseFloat(numString);

    return isNaN(num) ? null : num * rem;
  };

  return (
    <Above breakpoint="lg">
      {aboveLg => {
        return (
          <Below breakpoint="sm">
            {belowMd => {
              return (
                <section
                  className={cx(
                    'product-row-wrapper',
                    style,
                    fullWidth?.value && 'fullWidth'
                  )}
                >
                  <div className="wrapper">
                    <SharedSwiper
                      gap={
                        gap?.value ? remToPx(theme.layout.gaps[gap?.value]) : 0
                      }
                      slidesPerView={
                        aboveLg
                          ? desktopSlidesPerView
                          : belowMd
                          ? mobileSlidesPerView
                          : mediumSlidesPerView
                      }
                    >
                      {children}
                    </SharedSwiper>
                  </div>
                </section>
              );
            }}
          </Below>
        );
      }}
    </Above>
  );
};

export const StyledProductRow = ({
  fullWidth,
  gap,
  padding,
  children,
  title,
  link,
  buttonText,
  backgroundColor,
  textColor
}) => {
  return (
    <ProductRowOuterWrapper
      style={{
        maxWidth: `${fullWidth?.value ? '100%' : theme.layout.maxWidth}`,
        padding: `0 ${padding?.value ?? 0}px`
      }}
    >
      {title?.value && (
        <h2 style={{ color: `${textColor?.value}` }}>{title?.value}</h2>
      )}
      <ProductRow
        fullWidth={fullWidth}
        gap={gap}
        padding={padding}
        style={productRowStyle}
      >
        {children}
      </ProductRow>
    </ProductRowOuterWrapper>
  );
};
