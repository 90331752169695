import { css } from 'linaria';
import { theme } from '../../../Theme';

export const desktopHeaderStyles = css`
  width: 100%;
  background-color: ${theme.colors.white};
  z-index: 1000;
  display: block;
  position: relative;
  top: 0;
  transition: all, 0.2s ease;

  &.sticky {
    opacity: 1 !important;
    position: sticky;
  }
  &.scrolled {
    opacity: 0;
  }

  .logo-search-icons {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    height: 63px;
    .left {
      display: flex;
      align-items: center;
      gap: 30px;

      .WeOptics {
        height: 50px;
        color: ${theme.colors.black};
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 20px;
      .link-evaluator {
        height: fit-content;
        text-decoration: none;
      }
    }
  }

  .categories-and-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 20px;
    border-top: 1px solid ${theme.colors.border};
    border-bottom: 1px solid ${theme.colors.border};

    .left {
      display: flex;
      gap: 20px;
    }
    .right {
      display: flex;
      gap: 20px;
    }
  }
`;

export const desktopHeaderButtonStyles = css`
  position: relative;
  padding: 5px 0;
  background: white;
  color: ${theme.colors.black};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.fonts.desktop.p2}
  line-height: 100%;
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  ${theme.below.lg} {
    ${theme.fonts.mobile.p2}
    line-height: 100%;
    font-weight: ${theme.fontWeights.medium};
  }
`;

export const categoryMenuDesktopStyle = css`
  display: flex;
  /* flex-direction: column; */

  .custom {
    border-left: 1px solid ${theme.colors.border};
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 250px;
  }
  .category-title {
    color: ${theme.colors.black};
    text-decoration: none;
    ${theme.fonts.desktop.h4};
  }

  .category-menu-result {
    border-left: 1px solid ${theme.colors.border};
    columns: 4;
    column-rule: 1px solid ${theme.colors.border};
  }
`;

export const searchResultDesktopStyle = css`

  .search-title {
    display: block;
    color: ${theme.colors.black};
    text-decoration: none;
    font-size: 20px;
    line-height: 24px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 22px!important;
  }

  .results {
    margin-top: 36px;
    gap: 100px;
    display: flex;

       h4 {
        ${theme.fonts.desktop.p1}
        font-weight: ${theme.fontWeights.bold};
        line-height: 180%;
      }
      
    .left {
      width: max-content;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .categories{
        display: flex!important;
      flex-direction: column;
      }

      a {
        text-decoration: none;
      }

      .cta {
        margin-top: 20px;
        justify-self: flex-end;
        text-transform: uppercase;
      }
      

    }

    .right {
      width: 100%;

      .search-products{
        width: max-content;
        column-count: 2;
        > * {
          width: 100%;
      
        } 
      }

      
    }
  }
`;
