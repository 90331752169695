import React from 'react';
import { cx } from 'linaria';
import { headerDropdownStyle } from './HeaderDropdown.style';

export const HeaderDropdown = ({ open, setOpen, style, children }) => {
  //TODO - Add overlay
  //TODO - Add close button ?
  return (
    <div
      className={cx('header-dropdown', style, open && 'open')}
      onClick={() => setOpen?.(false)}
    >
      {children}
    </div>
  );
};

export const StyledHeaderDropdown = ({ open, setOpen, children }) => {
  return (
    <HeaderDropdown open={open} setOpen={setOpen} style={headerDropdownStyle}>
      {children}
    </HeaderDropdown>
  );
};
