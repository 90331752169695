import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { API_KEY } from './EmailjsGlobalThingis';
import { Form, Formik, Field } from 'formik';
import InputWithLabel from '../Forms/InputApplication';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import TrendButton from '../ui/Button';
import t from 'format-message';

const FormWrapper = styled('div')`
  width: 100%;

  .application-sent-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    h1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const FormStyle = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column-gap: 1rem;
`;

const StyledButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 215px;
  height: 45px;
  padding: 14px 0;
  border-radius: 30px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  background: ${theme.colors.darkgrey};
  ${theme.fonts.desktop.a}
  letter-spacing: 0.1em;
  margin: 0.5em 0;
  :hover {
    background: ${theme.colors.darkgrey};
  }
`;

const Message = styled('textarea')`
  height: 100px;
  background: #f6f6f6;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.15rem;
  padding-right: 40px;
  border: none;
  &.disabled {
    background: #ffffff;
    color: #808080;
  }
`;

const ErrorSpan = styled('span')`
  width: 215px;
  font-style: italic;
  font-size: 12px;
  padding: 0 0.5em;
  color: ${theme.colors.white};
  background: #eb0000;
  position: absolute;
  border-radius: 0 0 0.5rem 0.5rem;
`;

const Label = styled('Label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.1rem;
  display: block;
  .req {
    color: ${theme.colors.white};
    margin-left: 0.5em;
  }
  &.disabled {
    color: #808080;
  }
`;

const ApplicationForm = ({ setApplicationSent, applicationSent }) => {
  const form = useRef();

  const sendEmail = e => {
    e.preventDefault();
    //Reset all inputs
    // var allInputs = document.querySelectorAll('input');
    // allInputs.forEach(singleInput => singleInput.value = '');
    // var allTextareas = document.querySelectorAll('textarea');
    // allTextareas.forEach(singleTextarea => singleTextarea.value = '');

    // await new Promise((r) => setTimeout(r, 500));
    //       alert(JSON.stringify(values, null, 2));

    emailjs
      .sendForm(
        'service_881n8l7',
        'application_template',
        form.current,
        API_KEY
      )
      .then(
        result => {
          console.log(result.text);
          setApplicationSent(true);
        },
        error => {
          console.log(error.text);
          setApplicationSent(false);
        }
      );
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: ''
        }}
      >
        {applicationSent ? (
          <div className="application-sent-message">
            <h1>Din ansökan har skickats!</h1>
            <p>
              Vi kommer att kontakta dig inom kort. Om du har några frågor kan
              du kontakta oss på info@weoptics.se
            </p>
          </div>
        ) : (
          <FormStyle id="application" ref={form} onSubmit={sendEmail}>
            <InputWithLabel
              name="company_name"
              label="Företagsnamn *"
              type={'text'}
              placeholder="2-25 tecken"
              required={true}
              pattern={'[a-zA-ZÅÄÖåäö0-9 -]{2,25}'}
            />

            <InputWithLabel
              name="company_number"
              label="Organisationsnummer *"
              type={'text'}
              placeholder="xxxxxx-xxxx  (10 siffror)"
              required={true}
              pattern={'^([0-9]{6})-([0-9]{4})$'}
            />
            <InputWithLabel
              name="company_type"
              label="Butikstyp *"
              type={'text'}
              placeholder="Skriv fysisk, webb eller båda"
              required={true}
              pattern={'([fF]ysisk|[Ww]ebb|[Bb]åda)'}
            />
            <InputWithLabel
              name="applicant_name"
              label="Förnamn *"
              type={'text'}
              placeholder="2-25 tecken"
              required={true}
              pattern={'[a-zA-ZÅÄÖåäö0-9-]{2,25}'}
            />
            <InputWithLabel
              name="applicant_lastname"
              label="Efternamn *"
              type={'text'}
              placeholder="2-25 tecken"
              required={true}
              pattern={'[a-zA-ZÅÄÖåäö0-9-]{2,25}'}
            />
            <InputWithLabel
              name="applicant_email"
              label="Email *"
              type={'email'}
              placeholder="Skriv en giltig emailadress"
              required={true}
            />
            <InputWithLabel
              name="invoice_email"
              label="Email för fakturering *"
              type={'email'}
              placeholder="Skriv en giltig emailadress"
              required={true}
            />
            <InputWithLabel
              name="address_street"
              label="Företagsadress *"
              type={'text'}
              placeholder="2-50 tecken"
              required={true}
              pattern={'[a-zA-ZÅÄÖåäö0-9 ]{2,50}'}
            />
            <InputWithLabel
              name="address_code"
              label="Postnummer *"
              type={'text'}
              placeholder="5 siffror"
              required={true}
              pattern={'^[0-9 ]{5,6}$'}
            />
            <InputWithLabel
              name="address_city"
              label="Stad *"
              type={'text'}
              placeholder="2-50 tecken"
              required={true}
              pattern={'[a-zA-ZÅÄÖåäö0-9]{2,50}'}
            />
            <InputWithLabel
              name="country"
              label="Land *"
              type={'text'}
              placeholder="2-50 tecken"
              required={true}
              pattern={'[a-zA-ZÅÄÖåäö0-9]{2,50}'}
            />

            <InputWithLabel
              name="currency"
              label="Valuta *"
              type={'text'}
              placeholder="3 bokstäver"
              required={true}
              pattern={'^[a-zA-ZÅÄÖåäö]{3}$'}
            />
            <InputWithLabel
              name="website"
              label="Hemsida *"
              type={'text'}
              placeholder="www.exempel.se"
              required={true}
              pattern={'^[w]{3}.[a-z0-9-]{2,20}.[a-z]{2,4}$'}
            />
            <InputWithLabel
              name="phone"
              label="Telefonnummer *"
              type={'text'}
              placeholder="Fyll i ditt telefonnummer"
              required={true}
              pattern={'^[0-9]{6,20}$'}
            />
            <InputWithLabel
              name="mobile_phone"
              label="Mobilnummer *"
              type={'text'}
              placeholder="Fyll i ditt mobilnummer"
              required={true}
              pattern={'^[0-9]{6,20}$'}
            />

            <div>
              <Label htmlFor="message">Företagspresentation</Label>
              <Message
                id="message"
                name="company_presentation"
                placeholder="Skriv din text här"
                type="textarea"
              />
            </div>

            <StyledButton type="submit">{t('Send application')}</StyledButton>
          </FormStyle>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default ApplicationForm;
