import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import t from '@jetshop/intl';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import { css, cx } from 'linaria';
import React from 'react';
import Input, { Label } from '../../Forms/Input';
import { PID } from './PID';
import { CustomerType } from './CustomerType';
import { SmallCaps } from '../../ui/Headings';
import { activeSegment } from '../UI/Form';
import { theme } from '../../Theme';

const sectionStyle = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  width: 452px;
  overflow: hidden;

  ${theme.below.sm} {
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    width: 350px;
    height: auto;
  }
`;

export default function Address(props) {
  const { fields, setCountryByCode, countries } = useAddressFields();

  function focusSection() {
    // Used to add a highlight to this section when it is active
    if (!props.isActive) props.setActiveSection('address');
  }

  return (
    <section
      className={cx(
        'section',
        props.isActive ? 'active' : null,
        sectionStyle,
        activeSegment
      )}
    >
      {/* <SmallCaps className="heading">{t('Contact Details')}</SmallCaps>

      <p>
        {t(
          'Please fill in your contact details for shipping and communication.'
        )}
      </p> */}

      {/* <CustomerType /> */}

      {/* <div style={{ marginBottom: '2em' }}>
        <Label htmlFor="country">Country</Label>
        <CountriesInput
          onFocus={focusSection}
          name="country"
          onChange={e => setCountryByCode(e.currentTarget.value)}
          countries={countries}
        />
      </div> */}

      {/* <PID /> */}

      {fields?.map(field => {
        // Map over billingAddressFields and display them
        if (field.name === 'co') {
          return null;
        } else {
          return <Input onFocus={focusSection} {...field.inputProps} />;
        }
      })}
    </section>
  );
}
