import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { Redirect } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import LoadingPage from '../../LoadingPage';
import Address from './Address';
import LoginFields from './LoginFields';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { theme } from '../../Theme';
import ApplicationForm from '../../Emailjs/ApplicationForm';

const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;
  width: 600px;

  background-color: ${theme.colors.white};

  ${theme.below.md} {
    width: 500px;
  }
  ${theme.below.sm} {
    height: auto;
    width: 350px;
  }

  p {
    line-height: 1.5;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }

  .section {
    &:before {
      opacity: 0;
    }
    margin-bottom: 0rem;
    transition: opacity 0.6s ease;
    opacity: 0.5;

    &.active {
      opacity: 1;
      &:before {
        opacity: 1;
      }
    }
  }
`;

const ApplicationHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.marginTop};
  margin-bottom: 17px;

  &.customer {
    margin-top: rem;
  }
  &.not-customer {
    margin-top: 4rem;
  }

  h1 {
    font-size: 43px !important;
    font-weight: 700;
  }

  ${theme.below.lg} {
    margin-top: 5rem;
  }
  ${theme.below.md} {
    margin-top: 3rem;
  }
`;

const enterInformationInstruction = css`
  color: ${theme.colors.darkgrey};
  margin: 3em 0 3em 0;
`;

const FieldsContainer = css`
  border: 1px solid red;
`;

export const Spacer = styled('div')`
  height: ${props => props.distance};
`;

function ApplicationPage() {
  const [activeSection, setActiveSection] = useState('address');
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const [applicationSent, setApplicationSent] = useState(false);

  return (
    <div className="grayBg">
      {loggedIn ? (
        <ApplicationHeader className="customer">
          <h1>{t('You are already a customer')}</h1>
        </ApplicationHeader>
      ) : (
        <>
          <SignupFormProvider>
            <ApplicationHeader className="not-customer">
              <h1>{t('Application')}</h1>
            </ApplicationHeader>
            <MaxWidth>
              <Intl>{t => <Head data={{ title: t('Application') }} />}</Intl>
              {!applicationSent && (
                <p className={enterInformationInstruction}>
                  {t(
                    'Enter your personal information to apply for an account.'
                  )}
                </p>
              )}
              <ApplicationForm
                setApplicationSent={setApplicationSent}
                applicationSent={applicationSent}
              />
              <Spacer distance="1.5rem" />
            </MaxWidth>
          </SignupFormProvider>
        </>
      )}
    </div>
  );
}

export default ApplicationPage;
